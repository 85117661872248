import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import Icon from '../../icons'

import { useLocation } from '@reach/router'
import * as start from '../../../pages/content-hub/start.module.css'

const Search = () => {
  const location = useLocation()
  const [query, setQuery] = useState('')

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const searchQuery = params.get('query')
    if (searchQuery) {
      setQuery(searchQuery)
    }
  }, [location.search])

  const handleSearch = (e) => {
    e.preventDefault()
    navigate(`/content-hub/search?query=${query}`)
  }

  return (
    <div className={start.searchBoxWhite}>
      <form onSubmit={handleSearch}>
        <Icon symbol='search' />
        <input type='text' value={query} onChange={(e) => setQuery(e.target.value)} placeholder='Search' />
      </form>
    </div>
  )
}

export default Search
